import React from 'react';

const Projetos = () => (
  <div>
    <h1>Projetos</h1>
    <p>Informações sobre os projetos.</p>
  </div>
);

export default Projetos;
