import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import GastosImage1 from '../../imagem/campanha.jpg';

const Gastos = () => {
  return (
    <div className="container">
      <div className="flex-container">
        <div className="gastos-container">
          <Carousel showThumbs={false} autoPlay infiniteLoop>
            <div>
              <img src={GastosImage1} alt="Possível gasto de campanha 2024" />
            </div>
          </Carousel>
          <div>
          <h1 className="legend">Entrada de valor</h1>
          <p>Entrada de R$191,11 via arrecadação vaquinha </p>
          </div>
          <div>
          <h1 className="legend">Saida</h1>
          <p>Saida de R$100 para compra de 1000 colinha.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gastos;
