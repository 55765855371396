import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home';
import Contact from './pages/Contato';
import Projetos from './pages/Projetos';
import Gastos from './pages/Gastos';
import Pautas from './pages/Pautas';

const RoutesApp = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/Pautas" element={<Pautas />} />
      <Route exact path="/Contato" element={<Contact />} />
      <Route exact path="/Projetos" element={<Projetos />} />
      <Route exact path="/Gastos" element={<Gastos />} />
    </Routes>
  );
};

export default RoutesApp;
