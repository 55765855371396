import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem
} from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="dark" dark expand="md" className="justify-content-center">
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="m-auto" navbar>
          <NavItem>
            <RouterNavLink to="/" className="nav-link">Home</RouterNavLink>
          </NavItem>
          <NavItem>
            <RouterNavLink to="/Pautas" className="nav-link">Pautas</RouterNavLink>
          </NavItem>
          <NavItem>
  <a href="https://divulgacandcontas.tse.jus.br/divulga/#/candidato/SUDESTE/RJ/2045202024/190002065596/2024/59153" className="nav-link" target="_blank" rel="noopener noreferrer">
    Gastos com Campanha
  </a>
</NavItem>
          {/* <NavItem>
            <RouterNavLink to="/Contato" className="nav-link">Contato</RouterNavLink>
          </NavItem> */}
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default Menu;
