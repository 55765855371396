import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Menu from './components/Menu';
import reportWebVitals from './reportWebVitals';
import './pages/global.css';
import './pages/styler.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Menu />
            <App />
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
