import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Imagem1 from "../../imagem/image1.jpg";
import Imagem2 from "../../imagem/image2.png";
import { FaSquareXTwitter, FaSquareInstagram, FaSquareFacebook, FaSquareYoutube, FaTiktok } from "react-icons/fa6";


const Pautas = () => {
  return (
    <div className="container">
      <div className="flex-container">
        <div className="flex-texto">
          <section>
            <h1>Eu George Lucas tenho esta visão do que e necessário para Teresópolis:</h1>
            <br />
            <br />
            <p>Acredito em um enorme potencial para Teresópolis se tornar uma cidade autossustentável, próspera e justa para todos os seus habitantes.</p>
            <p>Como pré-candidato a vereador, comprometo-me a trabalhar incansavelmente para fazer essa visão acontecer.</p>

            <h1>Pilares fundamentais constituem a base de minha campanha:</h1>
            <br />

            <h1>1. Apoio às Pessoas com Deficiência (PCD)</h1> 

           <p>A nossa proposta visa a criação de associações comprometidas com a promoção e apoio aos PCD para padronizar e facilitar a 
            prestação dos serviços acima. Iniciaremos programas específicos para promover inclusão social e melhorar o ambiente urbano de todas as áreas 
            da cidade e ofereceremos treinamentos profissionais para mentoria com empresas locais para aumentar as oportunidades de emprego para os PCD. 
            Também forneceremos suporte psicológico e serviços de saúde compatíveis com o PCD para melhorar sua qualidade de vida. Por fim, encorajaremos os 
            PCD e as respectivas famílias a tornarem-se inscritas ativas em reuniões sociais e políticas, formando um conselho consultivo ao grupo PCD que proporcione
             orientação nas políticas locais e assegure que a sua voz seja ouvida.</p>
          <br /> 

            <h1>2. Redução de taxas e burocracia governamental:</h1>

            <p>Vamos focar em diminuir as taxas e burocracias que sobrecarregam nossos empreendedores e cidadãos.
               Menos taxas significam mais oportunidades para o crescimento dos negócios locais e mais dinheiro no bolso dos moradores.
                Vamos simplificar os processos governamentais para tornar Teresópolis um lugar mais acolhedor para se viver e investir.</p>
            <br />


            <h1>3. A expansão econômica sustentável:</h1>

            <p>Criar um ambiente favorável à expansão das empresas locais e à criação de empregos e renda,
              diversificando a economia da cidade. Investir na educação e qualificação profissional, preparando a mão
              de obra local para os desafios do mercado de trabalho. Promover o empreendedorismo e a criatividade,
              incentivando a criação de novas soluções para os problemas da cidade.</p>
            <br />
          </section>
        </div>

        {/* <div className="flex-item">
          <div className="donation-button-container">
            <button className="donation-button" onClick={() => window.open('https://arrecada.net/georgelucas')}>
            Doe Agora para minha campanha e faça parte da mudança que Teresópolis precisa !
            </button>
          </div>
          <div className="flex-container">
            <div className="icon">
              <FaSquareXTwitter onClick={() => window.open('https://x.com/Georgelucaslib')} />
            </div>
            <div className="icon">
              <FaSquareFacebook onClick={() => window.open('https://www.facebook.com/georgelucasdopovo/')} />
            </div>
            <div className="icon">
              <FaTiktok onClick={() => window.open('https://www.tiktok.com/@georgelucasnovo')} />
            </div>
          </div>
        </div> */}

      </div>
    </div>
  );
}

export default Pautas;
