import React from 'react';
import { FaSquareXTwitter, FaSquareInstagram, FaSquareFacebook, FaSquareYoutube, FaTiktok } from "react-icons/fa6";
import MyPhoto from '../../imagem/george-v3.png';

const Contato = () => (
  <div className="container">
  <div className="flex-container">
  <div className="contact-container">
    <div className="contact-left">
      <div className="social-icons">
        <a href="https://x.com/Georgelucaslib" target="_blank" rel="noopener noreferrer" className="social-icon"><FaSquareXTwitter /></a>
        <a href="https://www.facebook.com/georgelucasdosaopedro" target="_blank" rel="noopener noreferrer" className="social-icon"><FaSquareFacebook /></a>
        <a href="https://www.tiktok.com/@georgelucasnovo" target="_blank" rel="noopener noreferrer" className="social-icon"><FaTiktok /></a>
      </div>
      <br/>
      <br/>
      <div className="whatsapp-group">
        <h2>Grupo no WhatsApp</h2>
        <p>Participe do nosso grupo no WhatsApp para ficar por dentro das novidades.</p>
        <a href="https://chat.whatsapp.com/BBJD7dnw68E0frfvsvNGFO" target="_blank" rel="noopener noreferrer" className="group-link">Entrar no Grupo</a>
      </div>
      <br/>
      <br/>
      <br/>
      <div className="donation-button-container">
            <button className="donation-button" onClick={() => window.open('https://arrecada.net/georgelucas')}>
            Doe Agora para minha campanha e faça parte da mudança que Teresópolis precisa !
            </button>
      </div>
    </div>
    <div className="contact-right">
      <img src={MyPhoto} alt="Minha Foto" className="my-photo" />
    </div>
  </div>
  </div>
  </div>
);

export default Contato;
