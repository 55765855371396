import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Imagem1 from "../../imagem/image1.jpg";
import Imagem2 from "../../imagem/image2.png";
import { FaSquareXTwitter, FaSquareInstagram, FaSquareFacebook, FaSquareYoutube, FaTiktok } from "react-icons/fa6";
import MyPhoto from '../../imagem/george-v3.png';


const Home = () => {
  return (
    <div className="container">
      <div className="flex-container">
        <div className="flex-texto">
          <section>
            <h1>A História sobre George Lucas</h1>
            <br />
            <p>Nascido em Duque de Caxias onde vivi meus 4 primeiros anos depois em Bangu por longos 21 anos,
              conheci a falência de Bangu de perto, a área da saúde e de segurança pública totalmente
              sucateadas sem nenhuma oportunidade de mudança para a cidade de Bangu, minha família acabou
              chegando no fundo do posso com dívidas, a única forma que encontramos para conseguir
              sobreviver a isso foi fazendo uma refeição por dia no restaurante popular (1 real),
              me vi com desejo de fazer a mudança na vida da minha família, meu primeiro emprego 
              foi com 12 anos em uma lan house perto de onde eu morava neste momento consegui
              oferecer auxílio a minha mãe nas despesas da casa e neste momento acabamos saindo
              da linha da pobreza, em 2015 conheci a minha futura esposa Mariane e nesta mesma
              época também conheci Teresópolis, cidade linda com uma natureza exuberante; 
              porém, fico muito triste de ver como uma cidade tão linda tem tantos problemas 
              como um sistema de saúde que nos deixa na mão e ausência de infra estrutura para 
              pessoas como eu sou, que tem dificuldade de mobilidade. Eu amo a cidade que me acolheu
              e faço parte de um movimento de pessoas que luta pela melhora. Eu sempre fiz pequenas
              coisas como ligar para a ouvidoria para tapar um buraco que se formou depois das chuvas
              fortes ou consertar um poste caído, avisando sobre lâmpadas queimadas e até monitorar
              o tempo e usar minha página no facebook para avisar quando vai chover. Hoje, como
              pré-candidato, quero investir com mais força na mudança que a cidade precisa 
              e quero deixar minha marca. Porque a gente merece um posto de saúde sem 
              fila, calçadas boas e uma Teresópolis melhor.
            </p>
            <div className="donation-button-container">
                  <button className="donation-button" onClick={() => window.open('https://arrecada.net/georgelucas')}>
                  Doe Agora para minha campanha e faça parte da mudança que Teresópolis precisa !
                  </button>
            </div>
        </section>
        </div>
          <div className="contact-right">
          <img src={MyPhoto} alt="Minha Foto" className="my-photo" />
            <div className="social-icons">
            <a href="https://x.com/Georgelucaslib" target="_blank" rel="noopener noreferrer" className="social-icon"><FaSquareXTwitter /></a>
            <a href="https://www.facebook.com/georgelucasdosaopedro" target="_blank" rel="noopener noreferrer" className="social-icon"><FaSquareFacebook /></a>
            <a href="https://www.tiktok.com/@georgelucasnovo" target="_blank" rel="noopener noreferrer" className="social-icon"><FaTiktok /></a>
            </div>
            <div className="whatsapp-group">
            <h2>Grupo no WhatsApp</h2>
            <p>Participe do nosso grupo no WhatsApp para ficar por dentro das novidades.</p>
            <a href="https://chat.whatsapp.com/BBJD7dnw68E0frfvsvNGFO" target="_blank" rel="noopener noreferrer" className="group-link">Entrar no Grupo</a>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Home;
